<template>
  <div>
    <b-card :style="'min-height: 500px; background: ' + d_treeListData.style.backGround">
      <template v-if="d_showTree">
        <document-tree-list :p_treeListData="d_treeListData"></document-tree-list>
      </template>
    </b-card>
  </div>
</template>

<script>
import GlobalService from '@/services/global';
import WdmService from '@/services/wdm';
import {
  default as DocumentTreeList
} from "@/components/widgets/DocumentTreeList";
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import {
  default as router
} from '@/router';

export default {
  name: 'DocumentTree',
  computed: {
    ...mapGetters({})
  },
  mounted: function () {},
  data () {
    return {
      d_showTree: false,
      d_treeListData: {
        'style': {
          'menu': {
            'background': '#ededed',
            'padding': '0',
            'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'border': 'solid 1px black'
          },
          'backGround': 'white',
          'item': {
            'background': '#ededed',
            'color': 'black',
            'margin': '3',
            'width': '300',
            'padding': '3',
            // 'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'boxShadow': '',
            'minHeight': '',
            // 'border': 'solid 1px black',
            'border': '',
            'left_plus': '10'
          }
        },
        'list': [],
        'template': {
          'type': '2'
        },
        'items': [],
        'parent_wdmr_data': '',
        'parent': { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
          'key': '',
          'type': 'wdm231',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm231_v2.0',
          'type': 'wdm231',
          'bucket': 'wisdom',
          'other_possible_relations': [{
            'label': 'Web sayfaları ile ilişkilendir',
            'relation_type': 'keys',
            'parent': { 'key': 'wdm233_1', 'type': 'wdm233', 'bucket': 'wisdom', },
            'child': { 'reference_wdm_key': 'wdm_wisdom_wdm232_v2.0', 'type': 'wdm232', 'bucket': 'wisdom' }
          }]
        }
      }
    };
  },
  created: function () {
    if (this.$route.query.wdmr231) {
      this.d_treeListData.parent.key = 'wdm231_' + this.$route.query.wdmr231;
      let data = {
        'parent_wdm': this.d_treeListData.parent,
        'child_wdm': this.d_treeListData.child,
        'relation_type': 'keys',
        'type': 'only_relation_list', // only_relation_list or get_wdmr_data
        'layer_type': 'all' // first or all
      };
      WdmService.get_wdmr_tree_list(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_treeListData.list = resp.data.result.list;
            let data = {
              'key': this.d_treeListData.parent.key,
              'bucket': this.d_treeListData.parent.bucket
            };
            // console.log(data);
            GlobalService.get_one_cb_document(data)
              .then(resp => {
                let msg = '';
                if (resp.data.status === "success") {
                  this.d_treeListData.parent_wdmr_data = resp.data.result;
                  this.d_showTree = true;
                } else {
                  console.log('errorr get_one_cb_document : ', resp.data.message);
                }
              });
          } else {
            console.log('errorr get_wdmr_tree_list : ', resp.data.message);
          }
        });
    }
  },
  filters: {},
  methods: {},
  components: {
    DocumentTreeList
  }
};

</script>

